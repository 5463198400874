@import 'src/styles/breakpoints';
.categoryFilter {
  height: 100%;
  min-height: 64px;

  @include respond-below(m) {
    width: 100%;
    min-height: auto;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    margin: 0.25rem 0;
    border: none;
    background-color: white;
    font-size: var(--fontsize-body-l);
    font-weight: 500;
    width: 100%;
    height: 100%;
    color: black;
    text-decoration: none;
    text-align: center;
    color: var(--color-primary-black);
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }

    svg:first-child {
      margin-right: 0.75rem;
    }

    svg:last-child {
      margin-left: 0.75rem;
    }

    //does not work with all icon shapes

    //does not work with all icon shapes
    /* svg path {
      fill: var(--color-primary-black);
    }

    svg g path {
      fill: var(--color-primary-black);
    }*/

    span {
      text-align: left;
      text-overflow: ellipsis;
      &:nth-child(2) {
        width: 100%;
      }
    }

    &:focus {
      outline: 2px solid var(--color-primary-black);
      outline-offset: 0.25rem;
    }
  }

  &.withHorizontalPadding {
    a {
      padding: 0.75rem;
      margin: 0;

      &:focus {
        outline-offset: 0;
      }
    }
  }
}
