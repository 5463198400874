@import 'src/styles/breakpoints';

.toggleButton {
  border: 2px solid var(--color-white);
  color: var(--color-white);
  padding: 0 1rem;
  height: 2.25rem;
  margin: 0 0.75rem 1.25rem 0;

  &.isSelected {
    color: #464646;
    background-color: var(--color-white);
  }

  &:focus {
    outline: 2px solid var(--color-white);
    outline-offset: 2px;
  }

  @include respond_above(s) {
    display: none;
  }

  .innerWrapper {
    display: flex;
    align-items: center;

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 1rem;
    }
  }
}
