@import 'src/styles/breakpoints';

.dateSelectorMenu {
  background: var(--color-white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  min-width: fit-content;

  @include respond_above(s) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .button {
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    width: 100%;
    padding: 1rem;
    align-items: center;

    svg {
      color: var(--color-primary-black);
    }

    svg:first-child {
      margin-right: 1rem;
    }

    svg:last-child {
      margin-left: 0.75rem;
    }

    &:focus {
      outline: 2px solid var(--color-coat-of-arms);
      outline-offset: -2px;
    }
  }

  .hidden {
    display: none;
  }

  .checkboxWrapper {
    padding: 1rem;

    div {
      margin: 1rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .wrapper {
    padding: 1rem;
  }

  .buttonText {
    flex: 1 1 0%;
    text-align: left;
    color: var(--color-primary-black);
  }

  .btnSelectDates {
    border-top: 1px solid var(--color-silver);
    font-weight: 500;
  }

  .btnBack {
    font-weight: 500;
    border-bottom: 1px solid var(--color-silver);

    svg:first-child {
      margin-right: 0.75rem;
    }
  }

  .btnClose {
    border-top: 1px solid var(--color-silver);

    .buttonText {
      text-align: center;
    }
  }
}
