.dateInputsContainer {
  button {
    --selected-date-color: var(
      --color-input-selected-text,
      --color-primary-black
    );
    --selected-date-background: var(--color-input, --color-fog);
  }
  button:hover {
    --selected-date-color: var(--color-primary-black);
    --background-color: var(--color-input-light, --color-fog-light);
  }

  & > div:not(:first-child) {
    margin-top: var(--spacing-s);
  }

  svg {
    color: var(--color-primary-black);
  }
}
