.dateSelector {
  position: relative;
  background: var(--color-white);

  box-sizing: border-box;

  .button {
    background: transparent;
    border: none;
    min-height: 3.375rem;
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &:focus {
      outline: 3px solid var(--color-coat-of-arms);
      outline-offset: 2px;
    }
  }

  .iconWrapper {
    display: flex;
    align-self: center;

    svg {
      margin-right: 1rem;
      height: 1.25rem;
      width: 1.25rem;
      fill: var(--color-primary-black);
      & > g {
        color: var(--color-primary-black);
      }
    }
  }

  .info {
    flex: 1 1 0%;
    text-align: left;
    position: relative;
    height: 1.25rem;
    font-size: var(--fontsize-body-m);

    .buttonTextWrapper {
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 1.25rem;
      right: 0;
      text-overflow: ellipsis;
      color: var(--color-primary-black);
    }
  }

  .arrowWrapper {
    display: flex;
    align-self: center;

    svg {
      margin-left: 0.75rem;
      pointer-events: none;
      height: 1.25rem;
      width: 1.25rem;
      & > g {
        color: var(--color-primary-black);
      }
    }
  }
}

.isSelectedIndicator {
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--color-tram-dark);
}
