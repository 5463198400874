@import 'src/styles/breakpoints';

.mobileDateSelector {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: -1.25rem;

  @include respond_above(s) {
    display: none;
  }

  .iconCalendar {
    fill: var(--color-white);

    &.isSelected {
      fill: #464646;
    }
  }
}

.menuWrapper {
  display: inline;
}
