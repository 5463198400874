@import 'src/styles/breakpoints';

.mobileDateSelectorMenu {
  background: var(--color-white);
  color: var(--color-primary-black);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: absolute;
  left: 0;

  .button {
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    width: 100%;
    padding: 1rem;
    align-items: center;

    svg:first-child {
      margin-right: 1rem;
    }

    svg:last-child {
      margin-left: 0.75rem;
    }

    &:focus {
      outline-offset: -2px;
    }
  }

  .wrapper {
    padding: 1rem;
  }

  .buttonText {
    flex: 1 1 0%;
    text-align: left;
  }

  .btnClose {
    border-top: 1px solid var(--color-silver);

    .buttonText {
      text-align: center;
    }
  }
}
