.autosuggestMenu {
  --border-color-option: #f9f9f9;

  position: absolute;
  background: var(--color-white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  left: 0;
  top: 100%;
  z-index: 1;

  .title {
    padding: var(--spacing-s);
    text-align: center;
    color: var(--color-black-50);
    font-weight: 500;
    border-bottom: 1px solid var(--border-color-option);

    .closeButton {
      border: none;
      background: transparent;
      padding: 0;
      position: absolute;
      right: var(--spacing-s);
      margin-top: -2px;

      svg {
        display: block;
        margin: 0;
      }
      &:focus {
        outline: 2px solid var(--color-coat-of-arms);
        outline-offset: 2px;
      }
    }
  }

  .info {
    padding: var(--spacing-s);
    text-align: center;
    color: var(--color-black-50);
    font-weight: 500;
  }

  .autosuggestOptions {
    padding: 0;
    margin: 0;
  }

  .autosuggestOption {
    position: relative;
    border-bottom: 1px solid var(--border-color-option);
    display: flex;
    align-items: center;

    .text {
      cursor: pointer;
      padding: var(--spacing-s) var(--spacing-m);
    }
  }

  .autosuggestOption--isFocused {
    background-color: var(--color-fog);
  }
}
